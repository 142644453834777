<template>
    <div class="animate__animated animate__fadeInUp">
        <v-card-title>{{$t("support.faq.title")}}</v-card-title>

        <v-expansion-panels popout focusable style="width:100%">
            <v-expansion-panel v-for="index in $variables.v.faq_size"
                               :key="index">
                <v-expansion-panel-header>{{$t("support.faq.questions."+index)}}</v-expansion-panel-header>
                <v-expansion-panel-content class="mt-5">
                    {{$t("support.faq.answers."+index)}}
                </v-expansion-panel-content>
            </v-expansion-panel>
        </v-expansion-panels>
    </div>
</template>

<script>
    export default {
        metaInfo() {
            return {
                title: `${this.$t('meta.page-title-unformated')} - ${this.$t('meta.page-title-faq')}`,
                meta : [{
                    name   : "description",
                    content: this.$t("meta.description"),
                }]
            }
        },
    }
</script>